import { Box, InputAdornment, TextField } from "@mui/material";

import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../../Common/UI/TabPanel";
import AdminHeader from "./AdminHeader";
import AdminList from "./AdminList";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setAdministratorsSearchText,
  setAdministratorsType,
} from "../../../Redux/reducers/administratorsSlice";
import AdminUrlSetter from "./AdminUrlSetter";
import AdminUrlLoader from "./AdminUrlLoader";
import { useMemo, useState } from "react";
import { canModifyAdmins } from "../../../utils/roles";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

const Administrators = () => {
  const dispatch = useAppDispatch();
  const { type, urlLoaded, searchText } = useAppSelector(
    (state) => state.administrators
  );
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canModifyAdmins(userRole));
  const handleChange = (_: any, newValue: string) => {
    dispatch(setAdministratorsType(newValue));
  };
  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setAdministratorsSearchText(val));
      }, 500),
    [dispatch]
  );
  return urlLoaded ? (
    <>
      <AdminHeader hasModifyAccess={hasModifyAccess} />
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Master Admin"
            value="master_admin"
            {...a11yProps(0)}
          />
          <StyledTab label="Admin" value="admin" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <Box
          sx={{
            padding: "16px",
            bgcolor: "#FFFFFF",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <TextField
            onChange={(e) => modifySearchTerm(e.target.value)}
            style={{ width: "70%" }}
            placeholder="Search for Administrators"
            defaultValue={searchText}
            key={type}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <MainTabPanel value={type} index={type}>
          <AdminList hasModifyAccess={hasModifyAccess} />
        </MainTabPanel>
      </Box>
      <AdminUrlSetter />
    </>
  ) : (
    <AdminUrlLoader />
  );
};

export default Administrators;
