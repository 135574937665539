import { Box, Divider, Typography } from "@mui/material";
import {
  ChoiceContainer,
  DisplayText,
  PictureChoiceContainer,
  QuestionItemV2Container,
  QuestionItemV2Content,
  QuestionItemV2Header,
  UnansweredText,
  pictureChoiceItem,
  selectedChoiceContainer,
  selectedPictureChoiceItem,
} from "../style";
import { OnboardingBuilderStepIconMap } from "../../../../../../utils/onboarding";

const GroupItem = ({ questions }: { questions: any[] }) => {
  return (
    <Box mt={2}>
      {questions?.map((question) => {
        return <QuestionItem key={question.id} question={question} />;
      })}
    </Box>
  );
};

const ChoiceItem = ({ choice, selected, text }: any) => {
  return (
    <Box sx={selected ? selectedChoiceContainer : ChoiceContainer}>
      {choice.label}
      {choice.enableUserInput && text ? " - " + text : ""}
    </Box>
  );
};

const PictureItem = ({ choice, selected }: any) => {
  return (
    <Box sx={selected ? selectedPictureChoiceItem : pictureChoiceItem}>
      <img
        src={choice.iconUrl}
        alt={choice.label}
        style={{ height: "50px", width: "50px", objectFit: "contain" }}
      />
      <Box sx={{ mt: 1 }}>{choice.label}</Box>
    </Box>
  );
};

const ResponseItem = ({ question }: any) => {
  if (["multiple_choice", "likert_scale", "checkbox"].includes(question.type)) {
    return (
      <Box mt={2}>
        {question.choices.map((choice: any) => {
          const selected = question?.response?.stepChoiceIds?.includes(
            choice.id
          );
          return (
            <ChoiceItem
              key={choice.id}
              choice={choice}
              selected={selected}
              text={question?.response?.value}
            />
          );
        })}
      </Box>
    );
  }

  if (question.type === "picture_choice") {
    return (
      <Box sx={PictureChoiceContainer}>
        {question.choices.map((choice: any) => {
          const selected = question?.response?.stepChoiceIds?.includes(
            choice.id
          );
          return (
            <PictureItem key={choice.id} choice={choice} selected={selected} />
          );
        })}
      </Box>
    );
  }

  if (question.type === "statement") {
    return null;
  }

  return (
    <Box mt={2}>
      {question?.response?.value ? (
        <Typography>{question?.response?.value}</Typography>
      ) : (
        <Typography sx={UnansweredText}>Unanswered</Typography>
      )}
    </Box>
  );
};

const QuestionItem = ({ question }: any) => {
  const Icon = OnboardingBuilderStepIconMap[question.type];

  return (
    <Box sx={QuestionItemV2Container}>
      <Box sx={QuestionItemV2Header}>
        {Icon ? (
          <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
            <Icon />
          </Box>
        ) : null}
        <Typography sx={DisplayText}>{question.typeLabel}</Typography>
      </Box>
      <Divider />
      <Box sx={QuestionItemV2Content}>
        <Typography sx={DisplayText}>{question.title}</Typography>
        {question.type === "group" ? (
          <GroupItem questions={question.childSteps} />
        ) : (
          <ResponseItem question={question} />
        )}
      </Box>
    </Box>
  );
};

export default QuestionItem;
