import { v4 as uuid } from "uuid";
import { cardsV2, LessonQuestionsV2 } from "./../Components/CMS/CMSTypes";
import http from "./http";
import { toastMessage } from "./toast";
import { AxiosResponse } from "axios";

const valueOnlyTypes = ["title", "subtitle", "text"];
const textAndValueTypes = ["picture", "video", "audio"];

export const createNewSectionV2 = (type: string) => {
  const newSection: any = {
    ...cardsV2.find((card: any) => card.type === type),
    key: uuid(),
  };
  if (type === "question") {
    newSection["sectionChoices"] = [
      { key: uuid(), title: "", image: "", isCorrect: false },
    ];
    newSection["properties"] = { ...LessonQuestionsV2[0] };
  }
  if (type === "poll") {
    newSection["sectionChoices"] = [{ key: uuid(), title: "", image: "" }];
  }

  return newSection;
};

const handleResponseQuestionsV2 = (question: any) => {
  const ques: any = {
    questionTitle: question?.questionTitle,
    properties: {
      questionType: question?.properties.questionType,
      imageUrl: question?.properties.imageUrl || undefined,
    },
    sectionChoices: question?.sectionChoices || [],
  };

  if (ques.properties.questionType !== "text") {
    ques.properties.wrongExplanation =
      question?.properties.wrongExplanation || "";
    ques.properties.correctExplanation =
      question?.properties.correctExplanation || "";
    if (
      ques.properties.questionType === "single_select" ||
      ques.properties.questionType === "multi_select"
    ) {
      question?.sectionChoices.sort((a: any, b: any) => {
        return a?.position - b?.position;
      });
      ques.sectionChoices = question.sectionChoices.map((choice: any) => {
        return {
          title: choice.title,
          image: choice.image,
          key: choice.id,
          isCorrect: choice.isCorrect || false,
        };
      });
    } else if (ques.properties.questionType === "slider") {
      const properties = ques.properties;
      properties.maxValue = question?.properties?.maxValue || "";
      properties.minValue = question?.properties?.minValue || "";
      properties.midValue = question?.properties?.midValue || "";
      properties.maxLabel = question?.properties?.maxLabel || "";
      properties.midLabel = question?.properties?.midLabel || "";
      properties.minLabel = question?.properties?.minLabel || "";
      properties.cutoff = question?.properties?.cutoff || "";
    }
  }

  return ques;
};

const educationQuestionHandlerV2 = (section: any) => {
  const question: any = {
    questionTitle: section?.questionTitle,
    properties: {
      questionType: section.properties.questionType,
      imageUrl: section.properties.imageUrl || undefined,
    },
    sectionChoices: section?.sectionChoices || [],
  };

  if (!question.questionTitle) {
    throw new Error(`Question text of Assessment cannot be empty`);
  }
  if (question.properties.questionType !== "text") {
    question.properties.wrongExplanation =
      section?.properties?.wrongExplanation || undefined;
    question.properties.correctExplanation =
      section?.properties?.correctExplanation || undefined;

    if (
      question.properties.questionType === "single_select" ||
      question.properties.questionType === "multi_select"
    ) {
      question.sectionChoices = section?.sectionChoices.map(
        (choice: any, choiceIndex: number) => {
          if (!choice.title) {
            throw new Error(`Choice text of Assessment cannot be empty`);
          }
          return {
            title: choice.title,
            image: choice.image,
            position: choiceIndex + 1,
            isCorrect: choice.isCorrect || false,
          };
        }
      );

      if (question.sectionChoices.length < 2) {
        throw new Error(
          `Atleast two options has to be provided to the Assessment`
        );
      }
    } else if (question.properties.questionType === "slider") {
      const properties = question.properties;
      properties.maxLabel = section?.properties?.maxLabel || "";
      properties.minLabel = section?.properties?.minLabel || "";
      properties.midLabel = section?.properties?.midLabel || "";
      properties.maxValue = section?.properties?.maxValue || "";
      properties.minValue = section?.properties?.minValue || "";
      properties.midValue = section?.properties?.midValue || "";
      properties.cutoff = section?.properties?.cutoff || "";

      if (!properties.maxLabel || !properties.minLabel) {
        throw new Error(`Slider labels for Assessment cannot be empty`);
      }

      if (
        properties.maxValue === "" ||
        isNaN(properties.maxValue) ||
        properties.minValue === "" ||
        isNaN(properties.minValue)
      ) {
        throw new Error(`Slider values for Assessment must be a number`);
      } else {
        if (properties.midValue !== "" && !isNaN(properties.midValue)) {
          if (
            +properties.maxValue < +properties.midValue ||
            +properties.maxValue < +properties.minValue
          ) {
            throw new Error(
              `Slider Max value for Assessment cannot be less than mid and min value`
            );
          } else if (+properties.midValue < +properties.minValue) {
            throw new Error(
              `Slider Mid value for Assessment cannot be less than min value`
            );
          }
        } else {
          if (+properties.maxValue < +properties.minValue) {
            throw new Error(
              `Slider Max value for Assessment cannot be less than min value`
            );
          }
        }
      }
    }
  }

  return question;
};

export const educationResponseV2 = (sections: any[]) => {
  const newSections: any[] = [];

  const updatedSections = [...sections];
  updatedSections.sort((a: any, b: any) => {
    return a.position - b.position;
  });

  updatedSections.forEach((section) => {
    const typeDetails = cardsV2.find((card) => card.type === section.type);
    if (!typeDetails) {
      return;
    }
    const res: any = {
      ...typeDetails,
      key: section?.id,
      updatedAt: section?.updatedAt,
    };

    if (valueOnlyTypes.includes(section.type)) {
      res.value = section?.value;
    }

    if (
      textAndValueTypes.includes(section.type) ||
      section.type === "resource"
    ) {
      res.resourceTitle = section?.resourceTitle;
      res.resourceUrl = section?.resourceUrl;
      if (section.resourceAltTitle) {
        res.resourceAltTitle = section.resourceAltTitle;
      }
      if (section.transcript) {
        res.transcript = section.transcript;
      }
    } else if (section.type === "poll") {
      res.questionTitle = section?.questionTitle;
      if (section.sectionChoices) {
        const newChoices = [...section.sectionChoices];
        newChoices?.sort((a: any, b: any) => {
          return a.position - b.position;
        });
        res.sectionChoices =
          newChoices.map((choice: any) => {
            return {
              title: choice.title,
              image: choice.image,
              key: choice.id,
            };
          }) || [];
      }
    } else if (section.type === "question") {
      const question = handleResponseQuestionsV2(section);

      res.questionTitle = question?.questionTitle;
      res.properties = question?.properties;
      res.sectionChoices = question?.sectionChoices;
    }

    newSections.push(res);
  });

  return newSections;
};

export const educationRequestV2 = (section: any, modalType: string) => {
  const res: any = {
    type: section.type,
  };

  if (valueOnlyTypes.includes(section.type)) {
    res.value = section.value;

    if (!res.value) {
      throw new Error(`${section.name} cannot be empty`);
    }
  } else if (
    textAndValueTypes.includes(section.type) ||
    section.type === "resource"
  ) {
    res.resourceTitle = section.resourceTitle || null;
    res.resourceUrl = section.resourceUrl || null;

    if (!res.resourceTitle) {
      throw new Error(`${section.name} title cannot be empty`);
    }
    if (!res.resourceUrl) {
      throw new Error(`${section.name} link cannot be empty`);
    }

    if (section.type === "picture" && section.resourceAltTitle) {
      res.resourceAltTitle = section.resourceAltTitle || null;

      // if (!res.resourceAltTitle) {
      //   throw new Error(`${section.name} alt text cannot be empty`);
      // }
    }
  } else if (section.type === "poll") {
    res.questionTitle = section.questionTitle || null;
    if (!res.questionTitle) {
      throw new Error(`${section.name} title cannot be empty`);
    }
    const pollOptions = section?.sectionChoices.map(
      (choice: any, choiceIndex: number) => {
        if (!choice.title) {
          throw new Error(`Option text of Poll cannot be empty`);
        }
        return {
          title: choice.title,
          image: choice.image || undefined,
          position: choiceIndex + 1,
        };
      }
    );
    if (pollOptions.length < 2) {
      throw new Error(`Atleast two options has to be provided to the poll`);
    }

    if (modalType === "edit") {
      res.sectionChoices = pollOptions;
    } else {
      res.pollOptions = pollOptions;
    }
  } else if (section.type === "question") {
    const question = educationQuestionHandlerV2(section);

    res.questionTitle = question?.questionTitle;
    res.properties = question?.properties;

    if (modalType === "edit") {
      res.sectionChoices = question?.sectionChoices;
    } else {
      res.questionChoices = question?.sectionChoices;
    }
  }

  if (section.transcript) {
    res.transcript = section.transcript;
  }

  return res;
};

export const educationRequestForPreview = (sections: any[]) => {
  let position = 1;
  const newSections: any[] = [];

  sections.forEach((section) => {
    const res: any = {
      type: section.type,
      position: position,
    };

    if (valueOnlyTypes.includes(section.type)) {
      res.value = section?.value;
    }

    if (
      textAndValueTypes.includes(section.type) ||
      section.type === "resource"
    ) {
      res.resourceTitle = section?.resourceTitle;
      res.resourceUrl = section?.resourceUrl;

      if (section.type === "picture") {
        res.resourceAltTitle = section.resourceAltTitle || "";
      }
      if (section.transcript) {
        res.transcript = section.transcript;
      }
    }

    if (section.type === "poll" || section.type === "question") {
      res.questionTitle = section.questionTitle;
      res.sectionChoices = section.sectionChoices || [];
    }

    if (section.type === "question") {
      res.properties = section.properties;
    }

    newSections.push(res);
    position++;
  });

  return newSections;
};

const handlePageSection = (section: any) => {
  let tempSection = educationResponseV2([section]);
  let result = educationRequestV2(tempSection?.[0], "new");
  return result;
};

const createNewSections = async (
  sections: any,
  lessonId: string,
  pageId: string
) => {
  sections.sort(function (a: any, b: any) {
    return a.position - b.position;
  });

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const body = handlePageSection(section);
    await http.post(`/lmsv2/lesson/${lessonId}/pages/${pageId}/section`, body);
  }
};

export const createNewPages = async (pages: any, lessonId: any) => {
  pages.sort(function (a: any, b: any) {
    return a.position - b.position;
  });

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    const body = {
      title: page?.title,
    };
    const res = await http.post(`/lmsv2/lesson/${lessonId}/pages`, body);
    const pageId = res.data.data.id;
    await createNewSections(page.sections, lessonId, pageId);
  }
};

const constructLessonBody = (values: any) => {
  const obj = {
    name: values?.name + " Copy",
    externalName: values?.externalName || "",
    description: values?.description,
    imageUrl: values?.imageUrl,
    summary: values?.summary || "",
    lang: values?.lang || "en",
    readTime: values?.readTime || 1,
  };

  if (!obj.imageUrl) {
    delete obj.imageUrl;
  }

  return obj;
};

export const importPageV2 = async (page: any, lessonId: string) => {
  const body = {
    title: page?.title + " Copy",
  };
  const res = await http.post(`/lmsv2/lesson/${lessonId}/pages`, body);
  const pageId = res.data.data.id;
  await createNewSections(page.sections, lessonId, pageId);
};

export const importLesson = async (Ebody: any) => {
  let body = constructLessonBody(Ebody);

  if (Ebody.hasOwnProperty("pages")) {
    const res: AxiosResponse = await http.post(`/lmsv2/lessons`, body);

    if (Ebody.pages.length > 0) {
      await createNewPages(Ebody.pages, res.data.data.id);
    }
    return res.data.data.id;
  } else {
    toastMessage(
      "warning",
      "Please use Mahalo format education lesson JSON file"
    );
  }
};

export const previewLessonV2 = (sections: any[]) => {
  let questionPresent = false;
  let pollPresent = false;
  const previewSections = sections.map((section: any) => {
    const newSection = {
      ...section,
    };
    if (!newSection.id) {
      newSection.id = uuid();
    }
    if (section.resourceAltTitle) {
      newSection.resourceAltTitle = section.resourceAltTitle;
    }
    if (section.transcript) {
      newSection.transcript = section.transcript;
    }
    if (section.type === "question") {
      questionPresent = true;
      const ques: any = {
        title: newSection?.questionTitle || "",
        type: newSection?.properties?.questionType,
        imageUrl: newSection?.properties?.imageUrl || "",
      };
      if (ques.type === "single_select" || ques.type === "multi_select") {
        const choices = newSection?.sectionChoices.map((choice: any) => {
          return {
            ...choice,
            label: choice.title,
            id: choice.id || uuid(),
          };
        });
        ques.id = newSection.id || uuid();
        ques.choices = choices;
        ques.correctAnswer = choices
          .filter((choice: any) => choice.isCorrect)
          .map((choice: any) => choice.id);
        ques.correctExplanation = newSection?.properties?.correctExplanation;
        ques.wrongExplanation = newSection?.properties?.wrongExplanation;
      } else {
        ques.choices = [
          {
            minValue: newSection?.properties?.minValue || "",
            maxValue: newSection?.properties?.maxValue || "",
            midValue: newSection?.properties?.midValue || "",
            minLabel: newSection?.properties?.minLabel || "",
            maxLabel: newSection?.properties?.maxLabel || "",
            midLabel: newSection?.properties?.midLabel || "",
            cutoff: newSection?.properties?.cutoff || "",
          },
        ];
        ques.correctExplanation = newSection?.properties?.correctExplanation;
        ques.wrongExplanation = newSection?.properties?.wrongExplanation;
        ques.id = newSection.id || uuid();
      }
      delete newSection.properties;
      delete newSection.questionTitle;
      delete newSection.sectionChoices;
      newSection.questions = [ques];
    }
    if (section.type === "poll") {
      pollPresent = true;
      const newChoices = [...section?.sectionChoices];

      newChoices?.sort((a: any, b: any) => {
        return a.position - b.position;
      });
      newSection.sectionChoices = newChoices?.map((choice: any) => {
        return {
          title: choice.title,
          image: choice.image,
          id: choice.id || uuid(),
        };
      });
    }

    return newSection;
  });
  return { questionPresent, previewSections, pollPresent };
};

export const modifyAssessmentV2 = (type: string) => {
  if (type === LessonQuestionsV2[0]?.questionType) {
    return {
      sectionChoices: [{ key: uuid(), title: "", image: "", isCorrect: false }],
      properties: { ...LessonQuestionsV2[0] },
    };
  } else if (type === LessonQuestionsV2[1]?.questionType) {
    return {
      sectionChoices: [],
      properties: {
        ...LessonQuestionsV2[1],
        maxValue: "",
        minValue: "",
        midValue: "",
        maxLabel: "",
        midLabel: "",
        minLabel: "",
        cutoff: "",
      },
    };
  } else if (type === LessonQuestionsV2[2]?.questionType) {
    return {
      sectionChoices: [{ key: uuid(), title: "", image: "", isCorrect: false }],
      properties: { ...LessonQuestionsV2[2] },
    };
  } else if (type === LessonQuestionsV2[3]?.questionType) {
    return {
      sectionChoices: [],
      properties: { ...LessonQuestionsV2[3] },
    };
  }
};
