import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  highlightScript,
  pollAggregateHelper,
  pollAggregatePercentageHelper,
  // previewLessonProps,
} from "../../../utils/education";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  addBookmark,
  getBookmarks,
  removeBookmark,
} from "../../../utils/webview";
import webviewHttp from "../../../utils/webviewHttp";
import Webview from "./Webview";
import CMSThemeWrapper from "../Preview/CMSThemeWrapper";
import { getLang } from "../../../utils/lang";
import { getTranslation } from "../../../utils/webviewLang";
import {
  // logCustomGaEvent,
  setLessonDetails,
} from "../../../utils/webviewAnalytics";
import { previewLessonV2 } from "../../../utils/educationV2";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const WebviewWrapper: React.FC = () => {
  const { id } = useParams();
  const pageId = useQuery().get("pageId");

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [externalName, setExternalName] = useState("");
  // const [position, setPosition] = useState(1);
  const [pages, setPages] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sections, setSections] = useState<any[]>([]);
  const [bookmarks, setBookmarks] = useState<any[]>([]);
  const [answers, setAnswers] = useState<any>({});
  const [polls, setPolls] = useState<any>({});
  // const [spacedAnswer, setSpacedAnswer] = useState<any>({});

  const [isDarkMode] = useState(localStorage.getItem("theme") === "dark");
  const [lang] = useState(getLang());

  // const currentPageObj = pages[currentPage - 1];

  // useEffect(() => {
  //   if (currentPageObj?.id && id && lessonId) {
  //     const { pollPresent, questionPresent, spacedPresent } =
  //       previewLessonProps(currentPageObj?.sections);
  //     logCustomGaEvent("em_page_enter", {
  //       page_id: currentPageObj?.id,
  //       poll: pollPresent,
  //       question: questionPresent,
  //       spaced_learning: spacedPresent,
  //     });
  //   }
  //   return () => {
  //     if (currentPageObj?.id && id && lessonId) {
  //       logCustomGaEvent("em_page_exit", {
  //         page_id: currentPageObj?.id,
  //       });
  //     }
  //   };
  // }, [currentPageObj, id, lessonId]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (id) {
          setLoading(true);
          const res: AxiosResponse = await webviewHttp.get(
            `/lmsV2/lessons/${id}`
          );
          const lesson = res.data.data;
          setLessonDetails({
            lessonId: id,
            lessonTitle: lesson?.name || "",
            // unitId: id,
            // unitTitle: lesson.title,
          });
          let newPages = [...lesson.pages];
          newPages.sort((a: any, b: any) => {
            return a.position - b.position;
          });
          newPages = newPages.map((item: any) => {
            if (item.sections && item.sections.length > 0) {
              const newSections = [...item.sections];
              newSections.sort(function (a: any, b: any) {
                return a.position - b.position;
              });
              return {
                ...item,
                sections: newSections,
              };
            } else {
              return item;
            }
          });
          setTitle(lesson.name);
          setExternalName(lesson?.externalName);
          // setPosition(lesson.position || 1);
          setPages(newPages);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error, isDarkMode);
      }
    };
    fetchDetails();
  }, [id, setTitle, setPages, setLoading, isDarkMode]);

  useEffect(() => {
    if (pageId) {
      const index = pages.findIndex((page: any) => {
        return page.id === pageId;
      });
      if (index !== -1) {
        setCurrentPage(index + 1);
      } else {
        setCurrentPage(1);
      }
    } else {
      setCurrentPage(1);
    }
  }, [pageId, setCurrentPage, pages]);

  const fetchQuestionResponses = useCallback(
    async (pageId: string) => {
      const userId = localStorage.getItem("userId") || "";
      const res: AxiosResponse = await webviewHttp.get(
        `/lmsV2/lessons/${id}/question-response?userId=${userId}&pageId=${pageId}`
      );
      const ans: any = {};
      res.data.data.forEach((response: any) => {
        ans[response.sectionId] = {
          textValue: response.textValue || undefined,
          questionChoiceIds: response.questionChoiceIds || undefined,
          submitted: true,
        };
      });
      return ans;
    },
    [id]
  );

  // const fetchSpacedQuestionResponses = useCallback(
  //   async (pageId: string) => {
  //     const res: AxiosResponse = await webviewHttp.get(
  //       `/lms/lessons/${lessonId}/sl-response?educationLessonPageId=${pageId}`
  //     );
  //     const ans: any = {};
  //     res.data.data.forEach((response: any) => {
  //       ans[response.questionId] = {
  //         questionChoiceIds: response.questionChoiceIds || [],
  //         submitted: response.questionChoiceIds ? true : false,
  //         explanation: response.status
  //           ? response.status === "pass"
  //             ? "correct"
  //             : "wrong"
  //           : "",
  //       };
  //     });
  //     return ans;
  //   },
  //   [lessonId]
  // );

  const fetchPollResponses = useCallback(
    async (pageId: string) => {
      const userId = localStorage.getItem("userId") || "";
      const res: AxiosResponse = await webviewHttp.get(
        `/lmsV2/lessons/${id}/poll-response?userId=${userId}&pageId=${pageId}`
      );
      const ans: any = {};
      const aggregate: any = pollAggregateHelper(res.data.data?.groupResponses);
      res.data.data?.polls.forEach((response: any) => {
        const sectionAggregate = pollAggregatePercentageHelper(
          aggregate[response.id]
        );
        ans[response.id] = {
          value: response?.userResponses[0]?.id,
          submitted: true,
          aggregate: sectionAggregate,
        };
      });
      return ans;
    },
    [id]
  );

  useEffect(() => {
    const findLesson = async () => {
      try {
        setLoading(true);
        const sections = pages[currentPage - 1]?.sections;
        if (sections) {
          const {
            previewSections,
            questionPresent,
            pollPresent,
            // spacedPresent,
          } = previewLessonV2(sections);
          if (questionPresent) {
            const pageId = pages[currentPage - 1]?.id;
            try {
              const answer = await fetchQuestionResponses(pageId);
              setAnswers(answer);
            } catch (err) {
              setAnswers({});
              errorToastMessage(err as Error, isDarkMode);
            }
          } else {
            setAnswers({});
          }
          if (pollPresent) {
            const pageId = pages[currentPage - 1]?.id;
            try {
              const poll = await fetchPollResponses(pageId);
              setPolls(poll);
            } catch (err) {
              setPolls({});
              errorToastMessage(err as Error, isDarkMode);
            }
          } else {
            setPolls({});
          }
          // if (spacedPresent) {
          //   const pageId = pages[currentPage - 1]?.id;
          //   try {
          //     const spaced = await fetchSpacedQuestionResponses(pageId);
          //     setSpacedAnswer(spaced);
          //   } catch (err) {
          //     setSpacedAnswer({});
          //     errorToastMessage(err as Error, isDarkMode);
          //   }
          // } else {
          //   setSpacedAnswer({});
          // }
          setSections(previewSections);
        }
        if (typeof window !== "undefined") {
          window.scrollTo(0, 0);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error, isDarkMode);
      }
    };
    findLesson();
  }, [
    setSections,
    currentPage,
    pages,
    fetchQuestionResponses,
    fetchPollResponses,
    // fetchSpacedQuestionResponses,
    setLoading,
    setAnswers,
    isDarkMode,
  ]);

  const handlePagination = (offset: number, doNotlogEvent?: boolean) => {
    const newPage = currentPage + offset;
    if (newPage > 0 && newPage <= pages.length) {
      // if (offset === -1) {
      //   logCustomGaEvent("em_page_btn_back", {});
      // } else if (offset === 1 && !doNotlogEvent) {
      //   logCustomGaEvent("em_page_btn_next", {});
      // }
      setCurrentPage(newPage);
    } else if (newPage === pages.length + 1 || newPage === pages.length + 2) {
      finishHandler();
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = highlightScript();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getBookmarksHandler = useCallback(
    async (lessonId: any) => {
      try {
        setLoading(true);
        const res = await getBookmarks(lessonId);
        setBookmarks(res.data.data.map((book: any) => book?.pageId));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error, isDarkMode);
      }
    },
    [setBookmarks, setLoading, isDarkMode]
  );

  useEffect(() => {
    getBookmarksHandler(id);
  }, [getBookmarksHandler, id]);

  const finishHandler = async () => {
    try {
      // setLoading(true);
      // const res: AxiosResponse = await webviewHttp.get(
      //   `/lmsV2/lessons/${id}/finish-eligible`
      // );
      // if (!res.data.data) {
      //   toastMessage("warning", getTranslation("answer_all", lang), isDarkMode);
      //   setLoading(false);
      //   return;
      // }
      // if (currentPageObj?.id) {
      //   logCustomGaEvent("em_page_exit", {
      //     page_id: currentPageObj?.id,
      //   });
      // }
      // setLoading(false);
      if (
        localStorage.getItem("mobileOs") === "ios" &&
        //@ts-ignore
        window.webkit &&
        //@ts-ignore
        window.webkit.messageHandlers &&
        //@ts-ignore
        window.webkit.messageHandlers.callbackHandler
      ) {
        //@ts-ignore
        window.webkit.messageHandlers.callbackHandler.postMessage({
          command: "finish-lesson",
        });
        //@ts-ignore
      } else if (
        localStorage.getItem("mobileOs") === "RN" &&
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage
      ) {
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage?.(
          JSON.stringify({
            command: "finish-lesson",
          })
        );
        //@ts-ignore
      } else if (window.glowWebview) {
        //@ts-ignore
        window.glowWebview.finishHandler();
      }
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  // const moveToPage = (newPage: number) => {
  //   if (newPage > 0 && newPage <= pages.length) {
  //     setCurrentPage(newPage);
  //   }
  // };

  const toggleBookmark = async (pageId: string) => {
    try {
      if (id) {
        setLoading(true);
        if (bookmarks.includes(pageId)) {
          // logCustomGaEvent("em_page_bookmark_remove", {
          //   page_id: pageId,
          // });
          await removeBookmark(pageId, id);
        } else {
          // logCustomGaEvent("em_page_bookmark_add", {
          //   page_id: pageId,
          // });
          await addBookmark(
            {
              pageId: pageId,
            },
            id
          );
        }
        getBookmarksHandler(id);
      }
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  const modifyQuestionResponse = (
    questionId: string,
    key: string,
    value: string
  ) => {
    setAnswers((ans: any) => {
      return { ...ans, [questionId]: { [key]: value, submitted: false } };
    });
  };

  // const modifySpacedQuestionResponse = (
  //   questionId: string,
  //   key: string,
  //   value: string
  // ) => {
  //   setSpacedAnswer((ans: any) => {
  //     return { ...ans, [questionId]: { [key]: value, submitted: false } };
  //   });
  // };

  const submitQuestion = async (questions: any[], sectionId: string) => {
    try {
      setLoading(true);
      const response: any[] = [];
      const submittedQues: any[] = [];
      questions.forEach((ques) => {
        const pageId = pages[currentPage - 1]?.id;
        if (answers[ques.id]) {
          const answer = answers[ques.id];
          submittedQues.push(ques.id);
          response.push({
            questionId: ques.id,
            response: {
              questionChoiceIds: answer.questionChoiceIds,
              textValue:
                answer.textValue || typeof answer.textValue === "number"
                  ? answer.textValue.toString()
                  : answer.textValue,
            },
            questionType: ques.type,
            sectionId: sectionId,
            pageId,
          });
        }
      });
      // logCustomGaEvent("em_page_qn_btn_submit", {
      //   page_id: currentPageObj?.id,
      //   section_id: sectionId,
      //   response_present: response.length > 0 ? true : false,
      //   question_ids: response.map((res) => res.questionId).join(","),
      // });
      if (
        response.length > 0 &&
        (response?.[0].response?.textValue ||
          response?.[0].response?.questionChoiceIds?.length > 0)
      ) {
        await webviewHttp.post(
          `/lmsV2/lessons/${id}/submit-question-response`,
          { response }
        );
        setAnswers((prev: any) => {
          const newAns = { ...prev };
          submittedQues.forEach((ques) => {
            if (newAns[ques]) {
              newAns[ques] = {
                ...newAns[ques],
                submitted: true,
              };
            }
          });
          return newAns;
        });
        toastMessage(
          "success",
          getTranslation("response_saved", lang),
          isDarkMode
        );
      } else {
        toastMessage(
          "warning",
          getTranslation("no_response", lang),
          isDarkMode
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  // const submitSpacedQuestion = async (
  //   questionId: string,
  //   sectionId: string
  // ) => {
  //   try {
  //     setLoading(true);
  //     const ans = spacedAnswer[questionId];
  //     let response: any = null;
  //     if (ans) {
  //       response = {
  //         questionId: questionId,
  //         response: {
  //           questionChoiceIds: ans.questionChoiceIds,
  //         },
  //         educationLessonSectionId: sectionId,
  //       };
  //     }
  //     // logCustomGaEvent("em_page_sl_btn_submit", {
  //     //   page_id: currentPageObj?.id,
  //     //   section_id: sectionId,
  //     //   response_present: ans ? true : false,
  //     // });
  //     if (response) {
  //       await webviewHttp.post(
  //         `/lms/lessons/${lessonId}/submit-sl-question-response`,
  //         response
  //       );
  //       const answer = await fetchSpacedQuestionResponses(currentPageObj?.id);
  //       setSpacedAnswer(answer);
  //       toastMessage(
  //         "success",
  //         getTranslation("response_saved", lang),
  //         isDarkMode
  //       );
  //     } else {
  //       toastMessage("warning", getTranslation("no_answer", lang), isDarkMode);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     errorToastMessage(err as Error, isDarkMode);
  //   }
  // };

  const modifyPollResponse = (sectionId: string, value: string) => {
    setPolls((poll: any) => {
      return { ...poll, [sectionId]: { value: value, submitted: false } };
    });
  };

  const submitPoll = async (sectionId: string) => {
    try {
      setLoading(true);
      let response: any = {};
      // logCustomGaEvent("em_page_poll_btn_submit", {
      //   page_id: currentPageObj?.id,
      //   section_id: sectionId,
      //   response_present: polls[sectionId] ? true : false,
      // });
      if (polls[sectionId]) {
        const answer = polls[sectionId];
        response = {
          sectionId: sectionId,
          value: answer.value,
        };
        const res = await webviewHttp.post(
          `/lmsV2/lessons/${id}/submit-poll-response`,
          { ...response }
        );
        const groupAggregate: any = pollAggregateHelper(res.data.data);
        const aggregate = pollAggregatePercentageHelper(
          groupAggregate[sectionId]
        );
        setPolls((prev: any) => {
          const newPoll = { ...prev };
          if (newPoll[sectionId]) {
            newPoll[sectionId] = {
              ...newPoll[sectionId],
              submitted: true,
              aggregate: aggregate,
            };
          }
          return newPoll;
        });
        toastMessage("success", getTranslation("poll_saved", lang), isDarkMode);
      } else {
        toastMessage(
          "warning",
          getTranslation("no_poll_response", lang),
          isDarkMode
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  return (
    <CMSThemeWrapper isDarkMode={isDarkMode}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {title && pages?.length ? (
        <Webview
          externalName={externalName}
          // position={position}
          sections={sections}
          currentPage={currentPage}
          handlePagination={handlePagination}
          total={pages.length}
          // moveToPage={moveToPage}
          finishHandler={finishHandler}
          bookmarks={bookmarks}
          toggleBookmark={toggleBookmark}
          pageId={pages[currentPage - 1]?.id}
          answers={answers}
          modifyQuestionResponse={modifyQuestionResponse}
          submitQuestion={submitQuestion}
          modifyPollResponse={modifyPollResponse}
          submitPoll={submitPoll}
          polls={polls}
          isDarkMode={isDarkMode}
          // spacedAnswer={spacedAnswer}
          // modifySpaceResponse={modifySpacedQuestionResponse}
          // submitSpaceResponse={submitSpacedQuestion}
          lang={lang}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            bgcolor: isDarkMode ? "#252525" : "#fafafa",
          }}
        >
          <Typography variant="h2" color={isDarkMode ? "#fdfdff" : "#000"}>
            {title
              ? getTranslation("no_page", lang)
              : getTranslation("loading", lang)}
          </Typography>
        </Box>
      )}
    </CMSThemeWrapper>
  );
};

export default WebviewWrapper;
