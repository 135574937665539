import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import http from "../../../../utils/http";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../../Common/styles/modal";
import { LabelStyle } from "../../../Common/styles/form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const CaregiverDetails: React.FC<any> = ({
  setCaregiverModalId,
  caregiver,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const closeModal = () => {
    setCaregiverModalId(false);
  };

  const reinvite = async () => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse = await http.post(
        `/caregiver/clinic/resend-invite`,
        {
          userId: caregiver?.id,
        }
      );
      toastMessage("success", res.data.message);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={"Caregiver Details"} onCloseClick={closeModal} />
        <Box>
          <Box sx={{ display: "grid", gap: 3 }}>
            <Box>
              <Typography sx={LabelStyle}>Caregiver Name</Typography>
              <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                {caregiver.name || "-"}
              </Typography>
            </Box>
            <Box>
              <Typography sx={LabelStyle}>Email</Typography>
              <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                {caregiver.email}
              </Typography>
            </Box>
            <Box>
              <Typography sx={LabelStyle}>Phone Number</Typography>
              <PhoneInput
                value={caregiver?.phoneNumber}
                copyNumbersOnly={false}
                containerStyle={{
                  width: "100%",
                }}
                inputStyle={{
                  border: "none",
                  backgroundColor: "transparent",
                  paddingLeft: 0,
                  width: "fit-content",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
                buttonStyle={{
                  display: "none",
                }}
                disableDropdown={true}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
          {!caregiver?.verified && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              {!submitLoader ? (
                <>
                  <Button onClick={reinvite} variant="contained">
                    Re-Invite
                  </Button>
                  <Button onClick={closeModal} variant="outlined">
                    Cancel
                  </Button>
                </>
              ) : (
                <CircularProgress size={25} />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CaregiverDetails;
