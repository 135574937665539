import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LabelStyle } from "../../Common/styles/form";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import { NoDataContainer } from "../../Common/styles/table";
import { useAppSelector } from "../../../Redux/hooks";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const ClinicOverview = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { role } = useAppSelector((state) => state.user);
  const { id } = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const param = role === "clinician" ? "me" : id;
        const res: AxiosResponse = await http.get(`/clinic/${param}`);
        const response = res.data.data;
        setData({
          id: response?.id,
          name: response?.name,
          clinicName: response?.name,
          primaryContact: response?.primaryContact,
          email: response?.email,
          phoneNumber: response?.phoneNumber,
          address: response?.address,
        });
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    if (id || role === "clinician") {
      fetchDetails();
    } else {
      setData({});
      setLoading(false);
    }
  }, [id, setData, setLoading, role]);

  return (
    <Box p={4} bgcolor={"#FFFFFF"}>
      {!loading && data.id && (
        <Box sx={{ display: "grid", gap: 3 }}>
          <Box>
            <Typography sx={LabelStyle}>Primary Contact</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data.primaryContact}
            </Typography>
          </Box>
          <Box display={"flex"} gap={12}>
            <Box>
              <Typography sx={LabelStyle}>Email</Typography>
              <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                {data.email}
              </Typography>
            </Box>
            <Box>
              <Typography sx={LabelStyle}>Phone Number</Typography>
              <PhoneInput
                value={data?.phoneNumber}
                copyNumbersOnly={false}
                containerStyle={{
                  width: "100%",
                }}
                inputStyle={{
                  border: "none",
                  backgroundColor: "transparent",
                  paddingLeft: 0,
                  width: "fit-content",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
                buttonStyle={{
                  display: "none",
                }}
                disableDropdown={true}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Clinic Addresss</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data.address}
            </Typography>
          </Box>
        </Box>
      )}

      {!loading && !data.id && (
        <NoDataContainer>
          <Typography variant="body2" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default ClinicOverview;
