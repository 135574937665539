import { East, West } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import {
  useEffect,
  //  useLayoutEffect,
  useRef,
  // useState
} from "react";
import { previewContainer, previewWrapper } from "./style";
import PollQuestion from "./PollQuestion";
import WebQuestion from "./WebQuestion";
import { AudioPlayer, ImageSection, VideoPlayer } from "./WebviewMedia";

import "../CMSwebview.scss";
// import SpacedQuestion from "./SpacedQuestion";
import { getTranslation } from "../../../utils/webviewLang";
import WebviewResource from "./WebviewResource";

const Bookmark = ({ bookmarked, toggleBookmark, pageId, color }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="cp"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => toggleBookmark(pageId)}
    >
      <path
        d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
        stroke={color}
        fill={bookmarked ? color : "none"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const Webview = ({
  externalName,
  sections,
  currentPage,
  total,
  handlePagination,
  finishHandler,
  // position,
  bookmarks,
  pageId,
  toggleBookmark,
  modifyQuestionResponse,
  answers,
  submitQuestion,
  modifyPollResponse,
  submitPoll,
  polls,
  isDarkMode,
  // spacedAnswer,
  // modifySpaceResponse,
  // submitSpaceResponse,
  lang,
}: any) => {
  const topRef = useRef<HTMLDivElement>(null);
  // const [hideButtons, setHideButtons] = useState(false);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollTop = 0;
    }
  }, [sections]);

  // useLayoutEffect(() => {
  //   let spaced = null;
  //   sections.forEach((section: any) => {
  //     if (section.type === "spaced_learning") {
  //       spaced = section?.spacedLearning?.id;
  //     }
  //   });
  //   if (spaced && !spacedAnswer?.[spaced]?.submitted) {
  //     setHideButtons(true);
  //   } else {
  //     setHideButtons(false);
  //   }
  // }, [setHideButtons, sections, spacedAnswer]);

  const switchForm = (item: any) => {
    switch (item.type) {
      case "title":
        return (
          <div className="cms-title" key={item.id}>
            {item.value}
          </div>
        );
      case "subtitle":
        return (
          <div className="cms-subtitle" key={item.id}>
            {item.value}
          </div>
        );
      case "picture":
        return (
          <ImageSection item={item} key={item.id} pageId={pageId} lang={lang} />
        );
      case "question":
        return (
          <div className="cms-question" key={item.id}>
            <WebQuestion
              sectionId={item.id}
              questions={item.questions}
              answers={answers}
              modifyQuestionResponse={modifyQuestionResponse}
              submitQuestion={submitQuestion}
              pageId={pageId}
              lang={lang}
            />
          </div>
        );
      // case "spaced_learning":
      //   return (
      //     <div className="cms-question" key={item.id}>
      //       <SpacedQuestion
      //         pageId={pageId}
      //         sectionId={item.id}
      //         spacedLearning={item.spacedLearning}
      //         spacedAnswers={spacedAnswer}
      //         modifyQuestionResponse={modifySpaceResponse}
      //         submitQuestion={submitSpaceResponse}
      //         handlePagination={handlePagination}
      //         isDarkMode={isDarkMode}
      //         lang={lang}
      //       />
      //     </div>
      //   );
      case "text":
        return (
          <div className="cms-text" key={item.id}>
            <div dangerouslySetInnerHTML={{ __html: item.value }}></div>
          </div>
        );
      case "resource":
        return <WebviewResource item={item} key={item.id} pageId={pageId} />;
      case "video":
        return (
          <VideoPlayer item={item} key={item.id} pageId={pageId} lang={lang} />
        );
      case "audio":
        return (
          <AudioPlayer item={item} key={item.id} pageId={pageId} lang={lang} />
        );
      case "poll":
        return (
          <div className="cms-question" key={item.id}>
            <div className="question-container">
              <div className="question-title">{item?.questionTitle}</div>
              <PollQuestion
                sectionId={item.id}
                choices={item.sectionChoices}
                polls={polls}
                modifyPollResponse={modifyPollResponse}
                submitPoll={submitPoll}
                lang={lang}
              />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <Box
      className={
        isDarkMode ? "cms-webview-wrapper dark" : "cms-webview-wrapper"
      }
      sx={previewWrapper}
    >
      <Box className="cms-preview-container" sx={previewContainer} ref={topRef}>
        <div className="cms-pagination">
          {[...Array(total)].map((_, index: number) => {
            const width = (100 - total * 1) / total;
            return (
              <div
                className={index + 1 <= currentPage ? "filled" : "unfilled"}
                style={{ width: `${width}%` }}
                key={index}
              />
            );
          })}
        </div>
        <div className="cms-unit-details">
          <div className="cms-unit-details-wrapper">
            <div className="unit-title">{externalName}</div>
          </div>
          <Bookmark
            bookmarked={bookmarks.includes(pageId)}
            pageId={pageId}
            toggleBookmark={toggleBookmark}
            color={isDarkMode ? "#ffffff" : "#384670"}
          />
        </div>
        {sections?.map((section: any) => {
          return switchForm(section);
        })}
        <div className="button-container">
          <Button
            onClick={() => {
              if (currentPage !== 1) {
                handlePagination(-1);
              }
            }}
            disabled={currentPage === 1}
            sx={{ backgroundColor: isDarkMode ? "#2E2E2E" : "#EDEDEE" }}
          >
            <West />
          </Button>
          {
            // !hideButtons &&
            currentPage < total ? (
              <Button
                onClick={() => handlePagination(1)}
                endIcon={<East />}
                variant="contained"
              >
                {getTranslation("next", lang)}
              </Button>
            ) : (
              <Button onClick={finishHandler} variant="contained">
                {getTranslation("finish", lang)}
              </Button>
            )
          }
        </div>
      </Box>
    </Box>
  );
};

export default Webview;
