import { Box, Divider, SxProps, Typography } from "@mui/material";
import { exerciseEntry } from "./Exercises";
import { PhotoSizeSelectActualOutlined } from "@mui/icons-material";

const exerciseWrapper: SxProps = {
  border: 1,
  borderColor: "divider",
  p: 2,
  borderRadius: "12px",
  minHeight: "253px",
  // width: "272px",
  // mr: 2,
  // mb: 2,
  ".exerciseImage": {
    width: "100%",
    height: "130px",
    borderRadius: "12px 12px 0px 0px",
    objectFit: "cover",
  },
};

const ExercisesItem: React.FC<{ exercise: exerciseEntry }> = ({ exercise }) => {
  return (
    <Box sx={exerciseWrapper}>
      <Typography variant="h6" fontWeight={"medium"} color="#355962">
        {exercise.name}
      </Typography>
      <Divider sx={{ my: 2 }} />
      {exercise.imageUrl ? (
        <img
          src={exercise.imageUrl}
          className="exerciseImage"
          alt={exercise.name}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "130px",
            background: "#e0e3eb",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PhotoSizeSelectActualOutlined
            sx={{ width: "45px", height: "45px", color: "#355962" }}
          />
        </Box>
      )}
      <Typography variant="body1" color="#637E85" mt={1}>
        {exercise.time} mins
      </Typography>
    </Box>
  );
};

export default ExercisesItem;
