import { useEffect, useState } from "react";
import PrescriptionHeader from "./PrescriptionHeader";
import { errorToastMessage, toastMessage } from "../../../../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../../../../utils/http";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
} from "../../style";
import { Add, Delete, Edit, ExpandMore } from "@mui/icons-material";
import PrescriptionItem from "./PrescriptionItem";
import { NoDataContainer } from "../../../../../Common/styles/table";
import AddPrescriptionModal from "./AddPrescriptionModal";
import { DateTime } from "luxon";

const RoleMap: any = {
  doctor: "Clinician",
  self: "Patient",
  care_giver: "Caregiver",
};

const Prescription = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const { id } = useParams();
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchPrescriptionData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/medication/prescribe?userId=${id}`
        );
        const data = res.data.data;
        const values = Object.keys(data)
          .sort()
          .reverse()
          .map((key: any) => {
            const entries: any[] = [];
            data[key].forEach((entry: any) => {
              entry?.reminder?.timings?.forEach((med: any) => {
                const time = DateTime.fromFormat(
                  med?.timing,
                  "HH:mm:ss"
                ).toFormat("hh:mm a");
                entries.push({
                  id: med.id,
                  medicationId: entry.id,
                  name: entry.name,
                  strength: entry.strength,
                  frequency: entry?.reminder?.frequency,
                  notes: entry.notes,
                  dayTimings: entry?.reminder?.timings.map((time: any) => ({
                    timing: time.timing.slice(0, 5),
                    isReminderEnabled: time.isReminderEnabled,
                  })),
                  daysEnabled: entry?.reminder?.daysEnabled,
                  time: time,
                  hasCareGiver: entry.careGiverId !== null,
                  start: entry?.reminder.startDate
                    ? DateTime.fromISO(entry?.reminder.startDate).toFormat(
                        "dd/MM/yyyy"
                      )
                    : null,
                  end: entry?.reminder?.endDate
                    ? DateTime.fromISO(entry?.reminder.endDate).toFormat(
                        "dd/MM/yyyy"
                      )
                    : null,
                  startDate: entry?.reminder.startDate
                    ? DateTime.fromISO(entry?.reminder.startDate).toFormat(
                        "dd-MM-yyyy"
                      )
                    : "-",
                  endDate: entry?.reminder?.endDate
                    ? DateTime.fromISO(entry?.reminder.endDate).toFormat(
                        "dd-MM-yyyy"
                      )
                    : "-",
                  prescribedBy: RoleMap[entry?.createdUserRole],
                  pastEvent:
                    DateTime.local() > entry?.reminder.endDate ? true : false,
                });
              });
              if (entry?.reminder?.timings?.length === 0) {
                entries.push({
                  id: entry.id,
                  medicationId: entry.id,
                  name: entry.name,
                  strength: entry.strength,
                  frequency: entry?.reminder?.frequency,
                  notes: entry.notes,
                  daysEnabled: entry?.reminder?.daysEnabled,
                  time: "-",
                  hasCareGiver: entry.careGiverId !== null,
                  start: entry?.reminder.startDate
                    ? DateTime.fromISO(entry?.reminder.startDate).toFormat(
                        "dd/MM/yyyy"
                      )
                    : null,
                  end: entry?.reminder?.endDate
                    ? DateTime.fromISO(entry?.reminder.endDate).toFormat(
                        "dd/MM/yyyy"
                      )
                    : null,
                  startDate: entry?.reminder.startDate
                    ? DateTime.fromISO(entry.reminder.startDate).toFormat(
                        "dd-MM-yyyy"
                      )
                    : "-",
                  endDate: entry?.reminder.endDate
                    ? DateTime.fromISO(entry.reminder.endDate).toFormat(
                        "dd-MM-yyyy"
                      )
                    : "-",
                  prescribedBy: RoleMap[entry?.createdUserRole],
                  pastEvent:
                    DateTime.local() > entry?.reminder.endDate ? true : false,
                });
              }
            });

            return {
              entries: entries,
              dayLabel: DateTime.fromFormat(key || "", "yyyy-LL-dd").toFormat(
                "cccc"
              ),
              dateLabel: DateTime.fromFormat(key || "", "yyyy-LL-dd").toFormat(
                "dd LLLL yyyy"
              ),
            };
          });
        setData(values || []);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchPrescriptionData();
  }, [id, setLoading, setData, toggle]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    closeMenu();
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>, entry: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedEntry(entry);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedEntry(null);
  };

  const handleAction = async (action: string) => {
    try {
      if (action === "edit" && selectedEntry?.medicationId) {
        openModal();
      } else if (action === "delete" && selectedEntry?.medicationId) {
        setMenuLoader(true);
        const res: AxiosResponse = await http.delete(
          `/medication/${selectedEntry?.medicationId}`
        );
        setMenuLoader(false);
        toastMessage("success", res.data.message);
        closeMenu();
        refreshPage();
      }
    } catch (err) {
      errorToastMessage(err as Error);
      setMenuLoader(false);
    }
  };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };
  return (
    <>
      <PrescriptionHeader openModal={openModal} />
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={25} />
        </Box>
      ) : data.length > 0 ? (
        <>
          {data.map((item: any) => {
            return (
              <Accordion
                key={item?.dateLabel}
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === `panel${item.dateLabel}`}
                onChange={handleChange(`panel${item.dateLabel}`)}
                sx={JournalAccordion}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton>
                      <ExpandMore sx={{ fontSize: 24 }} />
                    </IconButton>
                  }
                  sx={{
                    ".Mui-expanded": {
                      m: "12px 0px !important",
                    },
                  }}
                >
                  <Box sx={JournalAccordionHeaderContainer}>
                    <Box sx={JournalAccordionHeader}>
                      <Box>
                        <Typography
                          fontWeight={600}
                          fontSize={"22px"}
                          color={"#355962"}
                          mb={0.5}
                        >
                          {item?.dateLabel}
                        </Typography>
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#355962"}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {item?.dayLabel}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    flexWrap: "wrap",
                    mb: 2,
                  }}
                >
                  {item.entries.map((entry: any) => {
                    return (
                      <PrescriptionItem
                        key={entry?.id}
                        entry={entry}
                        openMenu={openMenu}
                      />
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      ) : (
        <NoDataContainer
          sx={{
            height: "calc(100% - 104px)",
            borderRadius: "12px",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Typography variant="subtitle1" fontWeight="medium" color="#4B5563">
            No Prescribed medications
          </Typography>
          <Button variant="outlined" onClick={openModal} startIcon={<Add />}>
            Add
          </Button>
        </NoDataContainer>
      )}

      {showModal && (
        <AddPrescriptionModal
          showModal={showModal}
          closeModal={closeModal}
          entry={selectedEntry}
          refreshPage={refreshPage}
          userId={id}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            handleAction("edit");
          }}
          disabled={menuLoader}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          key="delete"
          onClick={() => {
            handleAction("delete");
          }}
          disabled={menuLoader}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          {menuLoader && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Prescription;
