import React, { useEffect, useState } from "react";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { Box, Typography } from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import ProgramInsights from "./ProgramInsightsTab/ProgramInsights";
import DTxInsights from "./DTxInsightsTab/DTxInsights";
import { useSearchParams } from "react-router-dom";

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState(searchParams.get("type") || "program");

  useEffect(() => {
    const params = new URLSearchParams();
    if (type) {
      params.set("type", type);
    }

    setSearchParams(params, {
      replace: false,
    });
  }, [type, setSearchParams]);

  const handleChange = (_: any, newType: string) => {
    setType(newType);
  };

  return (
    <>
      {/* <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography variant="h2">Dashboard</Typography>
        </Box>
      </StyledHeader> */}
      {/* <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Program Insights"
            value="program"
            {...a11yProps(0)}
          />
          <StyledTab label="DTx Insights" value="dtx" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <MainTabPanel value={type} index={"program"}>
          <ProgramInsights />
        </MainTabPanel>
        <MainTabPanel value={type} index={"dtx"}>
          <DTxInsights />
        </MainTabPanel>
      </Box> */}
    </>
  );
};

export default Dashboard;
