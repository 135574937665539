import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import http from "../../../../utils/http";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../../Common/styles/form";
import { ModalBaseStyles, ModalHeader } from "../../../Common/styles/modal";
import * as yup from "yup";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

let schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  phoneNumber: yup
    .string()
    .matches(/^\d{11}$/, "Enter a valid phone number")
    .required("Phone Number is Required"),
});

const AddCaregiverModal: React.FC<any> = ({
  setCaregiverModalId,
  caregiverModalId,
  patient,
  refresh,
}) => {
  const [initialData, setInitialData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { id } = useParams();

  const closeModal = () => {
    setCaregiverModalId(false);
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);

      const body = {
        ...values,
        userId: id,
      };

      let res: AxiosResponse;
      if (caregiverModalId !== "new") {
        // res = await http.patch(`/caregiver/${caregiverModalId}`, body);
        // toastMessage("success", res.data.message);
        // closeModal();
        // refresh();
        setSubmitLoader(false);
      } else {
        res = await http.post(`/caregiver/clinic/invite`, body);
        toastMessage("success", res.data.message);
        closeModal();
        refresh();
      }
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/caregiver/${caregiverModalId}`
        );
        const response = res.data.data;
        setInitialData({
          lastName: response?.lastName || "",
          firstName: response?.firstName || "",
          email: response?.email || "",
          phoneNumber: response?.phoneNumber || "",
        });

        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setCaregiverModalId(null);
      }
    };
    if (caregiverModalId && caregiverModalId !== "new") {
      fetchDetails();
    } else {
      setInitialData({});
      setLoading(false);
    }
  }, [caregiverModalId, setCaregiverModalId, setInitialData, setLoading]);

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={
            caregiverModalId === "new"
              ? "Add New Caregiver"
              : "Caregiver Details"
          }
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              firstName: initialData?.firstName || "",
              lastName: initialData?.lastName || "",
              email: initialData?.email || "",
              phoneNumber: initialData?.phoneNumber || "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({
              handleSubmit,
              getFieldProps,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="caregiver-name">
                      First Name
                    </FormLabel>
                    <TextField
                      placeholder="First Name"
                      id="caregiver-first-name"
                      {...getFieldProps("firstName")}
                      error={
                        touched?.firstName && errors?.firstName ? true : false
                      }
                      helperText={
                        touched?.firstName && errors?.firstName
                          ? (errors?.firstName as string)
                          : " "
                      }
                    />
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="caregiver-name">
                      Last Name
                    </FormLabel>
                    <TextField
                      placeholder="Last Name"
                      id="caregiver-last-name"
                      {...getFieldProps("lastName")}
                      error={
                        touched?.lastName && errors?.lastName ? true : false
                      }
                      helperText={
                        touched?.lastName && errors?.lastName
                          ? (errors?.lastName as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="email">
                      Email
                    </FormLabel>
                    <TextField
                      placeholder="Email"
                      id="email"
                      {...getFieldProps("email")}
                      error={touched?.email && errors?.email ? true : false}
                      helperText={
                        touched?.email && errors?.email
                          ? (errors?.email as string)
                          : " "
                      }
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      ...InputWrapper,
                      justifyContent: "unset",
                    }}
                  >
                    <FormLabel sx={LabelStyle} htmlFor="phone-number">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <PhoneInput
                      inputProps={{
                        id: "phone-number",
                      }}
                      isValid={
                        touched?.phoneNumber && errors?.phoneNumber
                          ? false
                          : true
                      }
                      value={values.phoneNumber}
                      onChange={(value) => setFieldValue("phoneNumber", value)}
                      country={"us"}
                      inputStyle={{
                        width: "100%",
                        height: "52px",
                        borderRadius: "6px",
                        fontSize: "16px",
                      }}
                    />
                    {touched?.phoneNumber && errors?.phoneNumber && (
                      <FormHelperText error>
                        {errors?.phoneNumber as string}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="patient">
                    Patient
                  </FormLabel>
                  <Typography mb={2}>{patient}</Typography>
                </FormControl>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddCaregiverModal;
