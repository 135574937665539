import { PersonOutlineOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  SxProps,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setHealthUser } from "../../../Redux/reducers/healthSlice";
import http from "../../../utils/http";
import AddCaregiverModal from "./Modal/AddCaregiverModal";
import CaregiverDetails from "./Modal/CaregiverDetails";
import { AxiosResponse } from "axios";

const HeaderStyle: SxProps = {
  height: "112px",
  width: "100%",
  borderRadius: "16px",
  background: "#FEFEFE",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  display: "flex",
  alignItems: "center",
  p: 2,
};

const InfoContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  pr: 2,
  mr: 2,
};

const BorderedInfoContainer: SxProps = {
  ...InfoContainer,
  borderRight: "1px solid",
  borderColor: "#E0E3EB",
};

const text1: SxProps = {
  color: "#111928",
  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "150%",
  mb: 1,
  textTransform: "capitalize",
};

const text2: SxProps = {
  color: "#6B7280",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "150%",
};

const HealthHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { userData } = useAppSelector((state) => state.health);
  const [toggle, setToggle] = useState(true);
  const { id } = useParams();

  const [showDetailModal, setDetailModal] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        dispatch(setHealthUser(null));
        const res = await http.get(`/participants/${id}/profile`);
        const response = res.data.data;
        const data = {
          email: response?.user?.email,
          phoneNumber: response?.user?.phoneNumber,
          name: response?.user?.firstName + " " + response?.user?.lastName,
          verified: response?.user?.isEmailVerified,
          gender: response?.gender,
          dob: response?.dob,
          caregiver: response?.user?.userCaregiver
            ? {
                id: response?.user?.userCaregiver?.careGiver?.id,
                name: response?.user?.userCaregiver?.careGiver?.firstName
                  ? response?.user?.userCaregiver?.careGiver?.firstName +
                    " " +
                    response?.user?.userCaregiver?.careGiver?.lastName
                  : "",
                email: response?.user?.userCaregiver?.careGiver?.email,
                phoneNumber:
                  response?.user?.userCaregiver?.careGiver?.phoneNumber,
                verified:
                  response?.user?.userCaregiver?.careGiver?.isEmailVerified,
              }
            : null,
        };
        dispatch(setHealthUser(data));
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [id, setLoading, dispatch, toggle]);

  const refresh = () => {
    setToggle((prev) => !prev);
  };
  const showAddCaregiverModal = () => {
    setAddModal(true);
  };

  const showCaregiverDetailsModal = () => {
    setDetailModal(true);
  };

  const reinvite = async () => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse = await http.post(
        `/caregiver/clinic/resend-invite`,
        {
          userId: id,
        }
      );
      toastMessage("success", res.data.message);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Box sx={HeaderStyle}>
      <Avatar
        variant={"square"}
        sx={{
          height: "80px",
          width: "80px",
          bgcolor: "lightgray",
          color: "#000",
          mr: 2,
        }}
      >
        <PersonOutlineOutlined fontSize="large" />
      </Avatar>
      <Box sx={BorderedInfoContainer}>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              sx={{ height: "27px", width: "100px", mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: "18px", width: "100px" }}
            />
          </>
        ) : (
          <>
            <Typography sx={text1}>{userData?.name || "Username"}</Typography>
            <Typography sx={text2}>{userData?.email || "email"}</Typography>
          </>
        )}
      </Box>
      <Box sx={BorderedInfoContainer}>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              sx={{ height: "27px", width: "100px", mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: "18px", width: "100px" }}
            />
          </>
        ) : (
          <>
            <Typography sx={text1}>{userData?.gender || "Gender"}</Typography>
            <Typography sx={text2}>Gender</Typography>
          </>
        )}
      </Box>
      <Box sx={BorderedInfoContainer}>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              sx={{ height: "27px", width: "100px", mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: "18px", width: "100px" }}
            />
          </>
        ) : (
          <>
            <Typography sx={text1}>
              {userData?.dob || "Date of Birth"}
            </Typography>
            <Typography sx={text2}>Birthday</Typography>
          </>
        )}
      </Box>
      <Box sx={InfoContainer}>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              sx={{ height: "27px", width: "100px", mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: "18px", width: "100px" }}
            />
          </>
        ) : (
          <>
            {userData?.caregiver ? (
              <Button onClick={showCaregiverDetailsModal}>
                {userData?.caregiver.name || "Caregiver Details"}
              </Button>
            ) : (
              <Button onClick={showAddCaregiverModal}>Add Caregiver</Button>
            )}
            <Typography sx={text2}>Caregiver</Typography>
          </>
        )}
      </Box>
      {!loading && !userData?.verified && (
        <Box sx={{ marginLeft: "auto" }}>
          {submitLoader ? (
            <CircularProgress size={25} />
          ) : (
            <Button onClick={reinvite} variant="contained">
              Re-Invite Patient
            </Button>
          )}
        </Box>
      )}
      {showAddModal && (
        <AddCaregiverModal
          setCaregiverModalId={setAddModal}
          caregiverModalId={"new"}
          patient={userData?.name}
          refresh={refresh}
        />
      )}
      {showDetailModal && (
        <CaregiverDetails
          caregiver={userData?.caregiver}
          setCaregiverModalId={setDetailModal}
        />
      )}
    </Box>
  );
};

export default HealthHeader;
