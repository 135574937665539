import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import http from "../../../utils/http";

let schema = yup.object().shape({
  name: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Clinic Name is Required"),
  primaryContact: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Primary Contact is Required"),
  email: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Email is Required"),
  phoneNumber: yup
    .string()
    .matches(/^\d{11}$/, "Enter a valid phone number")
    .transform((value) => (value ? value?.trim() : value))
    .required("Phone Number is Required"),
  address: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Address is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  refreshPage: () => void;
};

const AddClinicsModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      if (data?.id) {
        res = await http.patch(`/clinic/${data.id}`, values);
      } else {
        res = await http.post(`/clinic`, values);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={data?.id ? "Edit Clinic" : "Create New Clinic"}
          onCloseClick={closeModal}
        />

        <Formik
          initialValues={{
            name: data?.name || "",
            primaryContact: data?.primaryContact || "",
            email: data?.email || "",
            phoneNumber: data?.phoneNumber || "",
            address: data?.address || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            errors,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinic-name">
                    Clinic Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="clinic-name"
                    multiline
                    {...getFieldProps("name")}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name
                        ? (errors?.name as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="primaryContact">
                    Primary Contact <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="primaryContact"
                    {...getFieldProps("primaryContact")}
                    error={
                      touched?.primaryContact && errors?.primaryContact
                        ? true
                        : false
                    }
                    helperText={
                      touched?.primaryContact && errors?.primaryContact
                        ? (errors?.primaryContact as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="phone-number">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "phone-number",
                    }}
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.phoneNumber && errors?.phoneNumber ? false : true
                    }
                  />
                  {touched?.phoneNumber && errors?.phoneNumber && (
                    <FormHelperText error>
                      {errors?.phoneNumber as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address">
                    Address <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address"
                    {...getFieldProps("address")}
                    error={touched?.address && errors?.address ? true : false}
                    helperText={
                      touched?.address && errors?.address
                        ? (errors?.address as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddClinicsModal;
