import React, { useMemo } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  InputAdornment,
  // CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";

import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import { debounce } from "lodash";
import { Edit, MoreVert } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import AddClinicsModal from "./Modals/AddClinicsModal";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import SearchIcon from "@mui/icons-material/Search";
// import AddClinicsModal from "./AddClinicsModal";
type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasModifyAccess: boolean;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

const ClinicsList = ({
  page,
  setPage,
  showModal,
  setShowModal,
  hasModifyAccess,
  searchText,
  setSearchText,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [clinicsData, setClinicsData] = useState<any>([]);
  const [clinicsDataCount, setClinicsDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchClinicsData = async () => {
      try {
        setLoading(true);
        let url = `/clinic?page=${page + 1}&size=${pageSize}`;
        if (searchText) {
          url += `&search=${searchText}`;
        }
        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.rows?.map((row: any) => ({
          id: row?.id,
          name: row?.name,
          primaryContact: row?.primaryContact,
          email: row?.email,
          phoneNumber: row?.phoneNumber,
          address: row?.address,
        }));

        setClinicsData(newData || []);
        setClinicsDataCount(data?.count || 0);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchClinicsData();
  }, [page, toggleLoader, searchText]);

  const openModal = () => {
    setShowModal(true);
    setAnchorEl(null);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };
  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleRowClick = (module?: any) => {
    navigate(`/app/clinics/${module?.id}?title=${module?.name}`);
  };
  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
        setPage(0);
      }, 500),
    [setPage, setSearchText]
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          width: "100%",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "70%" }}
          placeholder="Search for Clinics"
          defaultValue={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ overflowX: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Clinic Name</StyledTableCell>
              <StyledTableCell>Primary Contact</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              {hasModifyAccess && <StyledTableCell />}
            </TableRow>
          </TableHead>
          {!loading && clinicsData?.length > 0 && (
            <>
              <TableBody>
                {clinicsData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell
                      sx={{
                        maxWidth: "250px",
                        cursor: "pointer",
                        color: "#327091",
                      }}
                      onClick={() => {
                        handleRowClick(row);
                      }}
                    >
                      <Typography noWrap fontWeight={600} fontSize="14px">
                        {row?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ textTransform: "capitalize" }}>
                      <Typography noWrap fontWeight={400} fontSize="14px">
                        {row?.primaryContact}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={400} fontSize="14px">
                        {row?.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <PhoneInput
                        value={row?.phoneNumber}
                        copyNumbersOnly={false}
                        containerStyle={{
                          width: "100%",
                        }}
                        inputStyle={{
                          border: "none",
                          backgroundColor: "transparent",
                          paddingLeft: 0,
                          width: "fit-content",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                        buttonStyle={{
                          display: "none",
                        }}
                        disableDropdown={true}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: "250px",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize="14px"
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          maxHeight: "40px",
                          overflow: "hidden",
                        }}
                      >
                        {row?.address}
                      </Typography>
                    </StyledTableCell>
                    {hasModifyAccess && (
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {clinicsDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={clinicsDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
      </Box>
      {!loading && clinicsData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={openModal}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      </Menu>
      {showModal && (
        <AddClinicsModal
          showModal={showModal}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )}
    </Box>
  );
};

export default ClinicsList;
