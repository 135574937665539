import { Add, Clear, Delete } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  addQuestionOption,
  changeQuestionOptionProperty,
  changeQuestionOptionEnableUserInput,
  deleteQuestionOption,
  setOnboardingLoader,
} from "../../../../Redux/reducers/onboardingSlice";
import { OnboardingQuestion } from "../../../../types/onboarding";
import { useCallback } from "react";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import { ImageUploadIcon } from "../../../Chatbot/Builder/Icons";
import { useDropzone } from "react-dropzone";
// import ReactQuill from "react-quill";
// import { quillFormats, quillModules } from "../../../../utils/reactquill";
import { MiniUploadWrapper } from "../../../Chatbot/Builder/chatbotBuilder.style";

type Props = {
  question: OnboardingQuestion;
  subIndex?: number;
};

type UploadProps = {
  index: number;
  subIndex?: number;
  image: any;
  editable: boolean;
};

const UploadItem: React.FC<UploadProps> = ({
  index,
  image,
  editable,
  subIndex,
}) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setOnboardingLoader(true));
          const url = await uploadFile(file, "questionnaire_image");
          dispatch(
            changeQuestionOptionProperty({
              type: "iconUrl",
              value: url,
              optionIndex: index,
              subIndex,
            })
          );
          dispatch(setOnboardingLoader(false));
        }
      } catch (err) {
        dispatch(setOnboardingLoader(true));
        errorToastMessage(err as Error);
      }
    },
    [index, dispatch, subIndex]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: editable ? onDrop : undefined,
    multiple: false,
    accept: {
      "image/*": [],
    },
    noClick: !editable,
  });

  const clearImage = () => {
    dispatch(
      changeQuestionOptionProperty({
        type: "iconUrl",
        value: null,
        optionIndex: index,
        subIndex,
      })
    );
  };

  return (
    <>
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...MiniUploadWrapper }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <ImageUploadIcon />
          )}
        </Box>
      </Box>
      {image && (
        <IconButton
          onClick={clearImage}
          title="Clear image"
          color="error"
          disabled={!editable}
        >
          <Clear />
        </IconButton>
      )}
    </>
  );
};

const QuestionOptions: React.FC<Props> = ({ question, subIndex }) => {
  const dispatch = useAppDispatch();
  const { questions } = useAppSelector((state) => state.onboarding);

  const { choices, type } = question;

  const changeOptionValue = (type: string, value: string, index: number) => {
    dispatch(
      changeQuestionOptionProperty({
        type,
        value,
        optionIndex: index,
        subIndex,
      })
    );
  };
  const changeEnableUserInput = (value: boolean, index: number) => {
    dispatch(
      changeQuestionOptionEnableUserInput({
        value,
        optionIndex: index,
        subIndex,
      })
    );
  };

  const addNewOption = () => {
    dispatch(addQuestionOption({ subIndex }));
  };

  const deleteOption = (index: number) => {
    dispatch(deleteQuestionOption({ optionIndex: index, subIndex }));
  };

  return (
    <>
      {choices?.map((option, index) => {
        return (
          <Box mb={2} key={option.id}>
            <Typography variant="subtitle1" mb={2} fontWeight="medium">
              {"Option " + (index + 1)}
            </Typography>
            <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label
              </Typography>
              <TextField
                fullWidth
                value={option.label}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("label", e.target.value, index)
                }
              />
            </Box>
            {/* <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label (Chinese)
              </Typography>
              <TextField
                fullWidth
                value={option.labelZh}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("labelZh", e.target.value, index)
                }
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label (Malay)
              </Typography>
              <TextField
                fullWidth
                value={option.labelMs}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("labelMs", e.target.value, index)
                }
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label (Tamil)
              </Typography>
              <TextField
                fullWidth
                value={option.labelTa}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("labelTa", e.target.value, index)
                }
              />
            </Box> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: "20px",
                flexWrap: "wrap",
                mb: 2,
              }}
            >
              <Box sx={{ flex: 1, minWidth: "200px" }}>
                <Typography variant="subtitle1" mb={2} fontWeight="medium">
                  Value
                </Typography>
                <TextField
                  fullWidth
                  value={option.value}
                  placeholder="Value"
                  onChange={(e) =>
                    changeOptionValue("value", e.target.value, index)
                  }
                />
              </Box>
              {type !== "likert_scale" && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <UploadItem
                    editable={true}
                    image={option.iconUrl}
                    index={index}
                    subIndex={subIndex}
                  />
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={addNewOption}>
                  <Add />
                </IconButton>
                <IconButton
                  onClick={() => deleteOption(index)}
                  disabled={choices.length === 1}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              {subIndex === undefined && (
                <Box sx={{ flex: 1, minWidth: "200px", mb: 2 }}>
                  <Typography variant="subtitle1" mb={2} fontWeight="medium">
                    Next Step (Optional)
                  </Typography>
                  <Select
                    fullWidth
                    value={option.nextStepId || ""}
                    onChange={(e) =>
                      changeOptionValue("nextStepId", e.target.value, index)
                    }
                  >
                    {questions.map((q, qIndex) => {
                      return q.id === question.id ? null : (
                        <MenuItem value={q.id} key={q.id}>
                          {q.variableName || "Question " + (qIndex + 1)}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={"exit"} key={"exit"}>
                      Exit
                    </MenuItem>
                  </Select>
                </Box>
              )}
              {(type === "multiple_choice" || type === "checkbox") && (
                <Box>
                  <Typography variant="subtitle1" mb={2} fontWeight="medium">
                    Enable User Input
                  </Typography>
                  <Switch
                    sx={{ mt: 2 }}
                    checked={option.enableUserInput}
                    onChange={(e) =>
                      changeEnableUserInput(e.target.checked, index)
                    }
                  />
                </Box>
              )}
            </Box>

            {/* <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.response}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("response", data, index)
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response in Chinese (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.responseZh}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("responseZh", data, index)
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response in Malay (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.responseMs}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("responseMs", data, index)
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response in Tamil (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.responseTa}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("responseTa", data, index)
                }
              />
            </Box> */}
          </Box>
        );
      })}
    </>
  );
};

export default QuestionOptions;
