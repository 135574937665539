import { Box, Typography } from "@mui/material";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import PatientRequestList from "./PatientRequestList";

const PatientRequests = () => {
  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Patient Requests
          </Typography>
        </Box>
      </StyledHeader>
      <PatientRequestList />
    </>
  );
};

export default PatientRequests;
