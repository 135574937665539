import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addQuestionUnitField,
  changeQuestionProperty,
  changeQuestionUnitsProperty,
  deleteQuestionUnitField,
} from "../../../../Redux/reducers/onboardingSlice";
import { OnboardingQuestion } from "../../../../types/onboarding";
import UploadItem from "./UploadItem";
import { Add, Delete } from "@mui/icons-material";

type Props = {
  question: OnboardingQuestion;
  subIndex?: number;
  builderType?: string;
};

const BasicDetails: React.FC<Props> = ({ question, subIndex, builderType }) => {
  const dispatch = useAppDispatch();
  const {
    variableName,
    title,
    // titleZh,
    // titleMs,
    // titleTa,
    exit,
    placeholder,
    isTooltip,
    tooltipLabel,
    tooltipValue,
    unitOfMeasurement,
    imageUrl,
    type,
    userType,
    description,
  } = question;

  const changeValue = (type: string, value: any) => {
    dispatch(changeQuestionProperty({ type, value, subIndex }));
  };
  const addNewField = () => {
    dispatch(addQuestionUnitField({ subIndex }));
  };
  const changeUnit = (index: number, value: string) => {
    dispatch(changeQuestionUnitsProperty({ index, value, subIndex }));
  };

  const deleteField = (index: number) => {
    dispatch(deleteQuestionUnitField({ index, subIndex }));
  };
  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title
        </Typography>
        <TextField
          fullWidth
          value={title}
          placeholder="Title"
          onChange={(e) => changeValue("title", e.target.value)}
          inputProps={{
            maxLength: 255,
          }}
        />
      </Box>
      {/* <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title (Chinese)
        </Typography>
        <TextField
          fullWidth
          value={titleZh}
          placeholder="Title"
          onChange={(e) => changeValue("titleZh", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title (Malay)
        </Typography>
        <TextField
          fullWidth
          value={titleMs}
          placeholder="Title"
          onChange={(e) => changeValue("titleMs", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title (Tamil)
        </Typography>
        <TextField
          fullWidth
          value={titleTa}
          placeholder="Title"
          onChange={(e) => changeValue("titleTa", e.target.value)}
        />
      </Box> */}
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Description
        </Typography>
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={5}
          value={description}
          placeholder="description (optional)"
          onChange={(e) => changeValue("description", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Variable Name
        </Typography>
        <TextField
          fullWidth
          value={variableName}
          placeholder="Variable Name"
          onChange={(e) => changeValue("variableName", e.target.value)}
        />
      </Box>
      {subIndex === undefined && (
        <Box mb={2}>
          <Typography variant="subtitle1" mb={2} fontWeight="medium">
            User Type (for progress)
          </Typography>
          <Select
            id="userType"
            fullWidth
            value={userType}
            onChange={(e) => changeValue("userType", e.target.value)}
          >
            <MenuItem key="patient" value={1}>
              Patient
            </MenuItem>
            <MenuItem key="Caregiver" value={2}>
              Caregiver
            </MenuItem>
            <MenuItem key="Both" value={0}>
              Both Caregiver and Patient
            </MenuItem>
          </Select>
        </Box>
      )}
      {type === "statement" && (
        <UploadItem image={imageUrl} subIndex={subIndex} />
      )}
      {subIndex === undefined && (
        <Box mb={2}>
          <Typography variant="subtitle1" mb={2} fontWeight="medium">
            {builderType === "daily_symptoms"
              ? "Exit Symptoms"
              : builderType === "daily_log"
              ? "Exit Daily Log"
              : "Exit Onboarding"}
          </Typography>
          <Switch
            checked={exit}
            onChange={(e) => changeValue("exit", e.target.checked)}
          />
        </Box>
      )}
      {(type === "text" || type === "number" || type === "date") && (
        <Box mb={2}>
          <Typography variant="subtitle1" mb={2} fontWeight="medium">
            Placeholder
          </Typography>
          <TextField
            fullWidth
            value={placeholder || ""}
            placeholder="Placeholder"
            onChange={(e) => changeValue("placeholder", e.target.value)}
          />
        </Box>
      )}

      {subIndex === undefined && (
        <Box mb={2}>
          <Typography variant="subtitle1" mb={2} fontWeight="medium">
            Tooltip
          </Typography>

          <Switch
            checked={isTooltip}
            onChange={(e) => changeValue("isTooltip", e.target.checked)}
          />
        </Box>
      )}

      {subIndex === undefined && isTooltip && (
        <>
          <Box mb={2}>
            <Typography variant="subtitle1" mb={2} fontWeight="medium">
              Tooltip Label
            </Typography>
            <TextField
              fullWidth
              value={tooltipLabel || ""}
              placeholder="Tooltip Label"
              onChange={(e) => changeValue("tooltipLabel", e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1" mb={2} fontWeight="medium">
              Tooltip Value
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={2}
              maxRows={5}
              value={tooltipValue || ""}
              placeholder="Tooltip Value"
              onChange={(e) => changeValue("tooltipValue", e.target.value)}
            />
          </Box>
        </>
      )}

      {type === "number" && unitOfMeasurement?.length === 0 && (
        <Button variant="contained" onClick={addNewField}>
          Add Units
        </Button>
      )}
      {type === "number" && unitOfMeasurement?.length > 0 && (
        <Box mb={2}>
          <Typography variant="subtitle1" mb={2} fontWeight="medium">
            Units
          </Typography>
          {unitOfMeasurement?.map((unit, index) => (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              key={index}
            >
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #adadad",
                  borderRadius: "8px",
                  height: "52px",
                  width: "52px",
                }}
              >
                {index + 1}
              </Typography>
              <TextField
                id={`unit-${index}`}
                fullWidth
                value={unit || ""}
                onChange={(e) => changeUnit(index, e.target.value)}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={addNewField}>
                  <Add />
                </IconButton>
                <IconButton
                  disabled={false}
                  color="error"
                  onClick={() => {
                    deleteField(index);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default BasicDetails;
