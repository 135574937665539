import { useAppSelector } from "../../../Redux/hooks";
import DailyLog from "./Block/Questionnaire/DailyLog/DailyLog";
import Exercises from "./Block/Exercises/Exercises";
import Food from "./Block/Food/Food";
import Medication from "./Block/Medication/Medication";
import Onboarding from "./Block/Questionnaire/Onboarding/Onboarding";
import Questionnaire from "./Block/Questionnaire/QuestionBlock/Questionnaire";

const HealthBlockSelector = () => {
  const { type } = useAppSelector((state) => state.health);

  switch (type) {
    case "food":
      return <Food />;
    case "onboarding":
      return <Onboarding />;
    case "medication":
      return <Medication />;
    case "exercise":
      return <Exercises />;
    case "daily_log":
      return <DailyLog type="daily-log" />;
    case "symptoms":
      return <DailyLog type="daily-symptoms" />;
    case "questionnaire":
      return <Questionnaire />;
    default:
      return null;
  }
};

export default HealthBlockSelector;
