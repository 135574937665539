import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import http from "../../../utils/http";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAppSelector } from "../../../Redux/hooks";

let schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  phoneNumber: yup
    .string()
    .matches(/^\d{11}$/, "Enter a valid phone number")
    .required("Phone Number is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
};

const AddClinicianModal = ({ showModal, closeModal, refreshPage }: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const { id } = useParams();
  const { role } = useAppSelector((state) => state.user);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      let url;
      if (role === "clinician") {
        url = `/clinic/clinician`;
      } else {
        url = `/clinic/${id}/clinician`;
      }
      res = await http.post(url, values);

      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title="Add New Clinician" onCloseClick={closeModal} />

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="first-name">
                    First Name
                  </FormLabel>
                  <TextField
                    id="first-name"
                    {...getFieldProps("firstName")}
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? (errors?.firstName as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="last-name">
                    Last Name
                  </FormLabel>
                  <TextField
                    id="last-name"
                    {...getFieldProps("lastName")}
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? (errors?.lastName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email ID
                  </FormLabel>
                  <TextField
                    id="email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="phone-number">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "phone-number",
                    }}
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.phoneNumber && errors?.phoneNumber ? false : true
                    }
                  />
                  {touched?.phoneNumber && errors?.phoneNumber && (
                    <FormHelperText error>
                      {errors?.phoneNumber as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddClinicianModal;
