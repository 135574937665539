import { Box, Divider, Typography } from "@mui/material";
import { NavLink, useMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  AdminIcon,
  Logo,
  ParticipantIcon,
  // MessagesIcon,
  // HealthIcon,
  ContentIcon,
  DashboardIcon,
  // MyAccountIcon,
  LogoutIcon,
  ExerciseIcon,
  MedicationIcon,
  // AppointmentsIcon,
  QuestionIcon,
  ClinicsIcon,
  PatientRequestIcon,
  AdminIconActive,
  ParticipantIconActive,
  QuestionIconActive,
  MedicationIconActive,
  DashboardIconActive,
  ClinicsIconActive,
  PatientRequestsActive,
  ContentIconActive,
  ExerciseIconActive,
  // PoweredByLogo,
} from "../Common/assets/Sidebar";
import http from "../../utils/http";
import { resetState } from "../../Redux/actions/resetAction";
import {
  canAccessMyClinic,
  canAccessDashboard,
  canViewAdministrators,
  canViewCMS,
  canViewClinicRequests,
  canViewClinics,
  canViewDailyLog,
  canViewExercise,
  canViewMedication,
  canViewOnboarding,
  canViewPatients,
  canViewQuestionnaire,
  canViewSymptoms,
} from "../../utils/roles";
import { useState } from "react";

const Sidebar = () => {
  const userRole = useAppSelector((state) => state.user.role);
  const [rbac] = useState({
    dashboard: canAccessDashboard.includes(userRole),
    patientDetails: canViewPatients.includes(userRole),
    clinics: canViewClinics.includes(userRole),
    patientRequest: canViewClinicRequests.includes(userRole),
    questionnaire: canViewQuestionnaire.includes(userRole),
    cms: canViewCMS.includes(userRole),
    exercise: canViewExercise.includes(userRole),
    onboarding: canViewOnboarding.includes(userRole),
    dailyLog: canViewDailyLog.includes(userRole),
    symptoms: canViewSymptoms.includes(userRole),
    medication: canViewMedication.includes(userRole),
    adminList: canViewAdministrators.includes(userRole),
    myClinic: canAccessMyClinic.includes(userRole),
  });

  const matchFunctions = {
    dashboard: useMatch("/app/dashboard/*"),
    patients: useMatch("/app/participants/*"),
    clinics: useMatch("/app/clinics/*"),
    myClinic: useMatch("/app/clinic/*"),
    patientRequest: useMatch("/app/patient-requests/*"),
    cms: useMatch("/app/cms/*"),
    exercise: useMatch("app/exercise/*"),
    questionnaire: useMatch("/app/question/*"),
    onboarding: useMatch("/app/onboarding/*"),
    dailyLog: useMatch("/app/daily-log/*"),
    symptoms: useMatch("/app/symptoms/*"),
    medication: useMatch("/app/medication/*"),
    adminList: useMatch("/app/administrators/*"),
  };
  const dispatch = useAppDispatch();

  const logout = async () => {
    try {
      await http.post("/auth/admin/logout");
    } catch (err) {
      console.log(err);
    } finally {
      localStorage.clear();
      dispatch(resetState());
    }
  };
  return (
    <Box className="sidebar">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: 1,
          borderBottomColor: "#E0E3EB",
          paddingBottom: 2,
          margin: "20px 0px",
        }}
      >
        <Logo />
      </Box>

      {rbac.dashboard && (
        <NavLink
          to="/app/dashboard"
          title="Dashboard"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.dashboard ? (
            <DashboardIconActive />
          ) : (
            <DashboardIcon />
          )}

          <Typography variant="subtitle1">Dashboard</Typography>
        </NavLink>
      )}
      {rbac.patientDetails && (
        <NavLink
          title="Patients"
          to="/app/participants"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.patients ? (
            <ParticipantIconActive />
          ) : (
            <ParticipantIcon />
          )}
          <Typography variant="subtitle1">Patients</Typography>
        </NavLink>
      )}
      {rbac.clinics && (
        <NavLink
          title="Clinics"
          to="/app/clinics"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.clinics ? <ClinicsIconActive /> : <ClinicsIcon />}

          <Typography variant="subtitle1">Clinics</Typography>
        </NavLink>
      )}
      {rbac.myClinic && (
        <NavLink
          title="Clinics"
          to="/app/clinic"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.myClinic ? <ClinicsIconActive /> : <ClinicsIcon />}
          <Typography variant="subtitle1">My Clinic</Typography>
        </NavLink>
      )}
      {rbac.patientRequest && (
        <NavLink
          title="Patient Request"
          to="/app/patient-requests"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.patientRequest ? (
            <PatientRequestsActive />
          ) : (
            <PatientRequestIcon />
          )}

          <Typography variant="subtitle1">Patient Requests</Typography>
        </NavLink>
      )}
      {rbac.questionnaire && (
        <NavLink
          to="/app/question"
          title="Questionnaire"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.questionnaire ? (
            <QuestionIconActive />
          ) : (
            <QuestionIcon />
          )}

          <Typography variant="subtitle1">Questionnaire</Typography>
        </NavLink>
      )}
      {rbac.cms && (
        <NavLink
          to="/app/cms"
          title="Content"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.cms ? <ContentIconActive /> : <ContentIcon />}

          <Typography variant="subtitle1">Content</Typography>
        </NavLink>
      )}
      {rbac.exercise && (
        <NavLink
          to="/app/exercise"
          title="Exercise"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.exercise ? <ExerciseIconActive /> : <ExerciseIcon />}

          <Typography variant="subtitle1">Exercise</Typography>
        </NavLink>
      )}
      {rbac.onboarding && (
        <NavLink
          to="/app/onboarding"
          title="Onboarding"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.onboarding ? (
            <QuestionIconActive />
          ) : (
            <QuestionIcon />
          )}
          <Typography variant="subtitle1">Onboarding</Typography>
        </NavLink>
      )}
      {rbac.dailyLog && (
        <NavLink
          to="/app/daily-log"
          title="Daily Log"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.dailyLog ? <QuestionIconActive /> : <QuestionIcon />}
          <Typography variant="subtitle1">Daily Log</Typography>
        </NavLink>
      )}
      {rbac.symptoms && (
        <NavLink
          to="/app/symptoms"
          title="Symptoms"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.symptoms ? <QuestionIconActive /> : <QuestionIcon />}
          <Typography variant="subtitle1">Symptoms</Typography>
        </NavLink>
      )}
      {rbac.medication && (
        <NavLink
          to="/app/medication"
          title="Medication"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.medication ? (
            <MedicationIconActive />
          ) : (
            <MedicationIcon />
          )}

          <Typography variant="subtitle1">Medication</Typography>
        </NavLink>
      )}
      {rbac.adminList && (
        <NavLink
          to="/app/administrators"
          title="Admins"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          {matchFunctions.adminList ? <AdminIconActive /> : <AdminIcon />}
          <Typography variant="subtitle1">Admins</Typography>
        </NavLink>
      )}
      <Divider sx={{ my: 2 }} />
      {/* <NavLink
        to="/app/my-account"
        title="My account"
        className={({ isActive }) =>
          isActive ? "sider-menu active" : "sider-menu"
        }
      >
        <MyAccountIcon />
        <Typography variant="subtitle1">My account</Typography>
      </NavLink> */}
      <Box className="sider-menu" onClick={logout}>
        <LogoutIcon />
        <Typography variant="subtitle1">Logout</Typography>
      </Box>
      {/* <Box
        sx={{
          mt: "auto",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <PoweredByLogo />
        <Typography variant="body1" fontWeight={"medium"} color="gray">
          Powered by Mahalo
        </Typography>
      </Box> */}
    </Box>
  );
};

export default Sidebar;
